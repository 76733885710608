<template>
  <ul class="menu-nav">
    <!-- <router-link
        :to="{ name: 'dashboard'}"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">ダッシュボード </span>
        </a>
      </li>
    </router-link> -->

    <router-link
        :to="{ name: 'canlendar'}"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
         <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">カレンダー</span>
        </a>
      </li>
    </router-link>

    <router-link
        :to="{ name: 'order'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">予約一覧</span>
        </a>
      </li>
    </router-link>

    <router-link
        :to="{ name: 'rest-time'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">予約不可の設定</span>
        </a>
      </li>
    </router-link>

    <router-link
        :to="{ name: 'list-rental'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">レンタルの管理</span>
        </a>
      </li>
    </router-link>

    <router-link
        :to="{ name: 'transaction'}"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
         <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">決済の管理</span>
        </a>
      </li>
    </router-link>

    <router-link
        :to="{ name: 'notifi'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">お知らせメール一覧</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
